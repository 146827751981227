<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            A nyelvtan fontosabb, mint gondolnád
          </h2>
          <p class="hashText">- A csaló megkeresések nyomában</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Neked szemet szúrnak a helyesírási hibák, amikor online tartalmat olvasol? Sokaknak fel sem tűnik egy-egy elvétett vesszőhiba, extra szóköz, vagy helytelen ragozás, azonban ezek tipikusan azok az árulkodó jelek, amelyek segítségével a csalók egy pillanat alatt lebuktatják saját magukat.</p>

                <p class="text">
                A közösségi média felületeken egyre gyakrabban lehet találkozni hamis nyereményjátékokkal, email-es megkeresésekkel, ál-ismerkedésekkel, amelyeken keresztül a szélhámosok az ártatlanoktól próbálják kicsalni a személyes, illetve bankkártya adatokat, vagy pénzüket. Te tudod, hogy mire kell figyelni, milyen jelek árulkodnak egy átverésről?</p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/nyelvtan.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Hibás szövegezés:</b> Ha furcsa a szórend, a szavak végén a ragozás hibás, esetleg teljesen el is marad, olyan, mintha fordítóprogrammal készült volna, akkor biztos lehetsz a csalásban. Az is feltűnő lehet, ha az üzenet elején valaki magázza a címzettet, aztán tegezésbe vált át. Az ilyen üzeneteknél biztos lehetsz benne, hogy te vagy a következő átverés kiszemeltje.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Privát üzenet:</b> Biztos te is találkoztál már olyan megkereséssel, amelyben egy arab herceg kér anyagi segítséget, hogy az örökségét visszaszerezhesse, és kedvességedért cserébe bőségesen megjutalmazna. Talán azt gondolnád, hogy ennek már senki sem dől be, de sajnos nem így van. Gyakoriak azok a segítségkérések is, amiket egy rokon nevében küldenek, a jószándékú hozzátartozó pedig gondolkodás nélkül küldi a kért összeget. Elég csak pár segítő szándékú emberrel eljátszani ezt, és a bűnözők máris tetemes összeggel lesznek gazdagabbak.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Kétes linkek: </b> A mai napig találkozni olyan hirdetésekkel, amikben egy bizonyos link szerepel. Ezen a linken keresztül 1 forintért lehet márkás telefont, laptopot, vagy bármi mást venni. De sietni kell, hisz korlátozott számban érhető el az akció. A probléma ott kezdődik, hogy az ilyen linkeken keresztül sok veszély fenyegeti az embert. Egy kém alkalmazás, ami észrevétlenül telepíti magát, vagy egy vírus pont elég bajt tudnak okozni.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Extra költségek:</b> Előfordul, hogy egy nyereményjáték során a nyeremény ingyen van, de a szállítási díjat be akarják hajtani a nyertesen. Egy ilyen szemfényvesztésnek sose dőlj be, még akkor se, ha csak pár száz forintról lenne szó. Lehet, hogy neked nem nagy összeg az a tanulópénz egy soha meg nem érkező nyereményért, viszont gondolj bele mennyit pénzről lenne szó, ha csupán 1000 embert át tudnak verni 300 forintos szállítási költséggel.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Vigyázz, vírus! </b>Még mindig gyakoriak a vírusokkal való visszaélések, hisz azoktól mindenki fél. Képzeld el, hogy kapsz egy email-t, amely szerint valószínűleg vírustámadás áldozata lettél, de nem kell aggódnod, hisz a megoldást is rejti az üzenet. Ilyen esetben vírusírtó programot is ajánlanak, akár egy hónapos ingyenes tesztidőszakkal. Csábító, nem igaz? Úgy érzed, figyelnek rád, megmentettek, és még ingyen is van. Hidd el, semmi sincs ingyen, a vírust pont az a “vírusírtó” tartalmazza, amit meleg szívvel ajánlottak neked. Elég egy kattintás, és már látják is, hogy mit csinálsz, milyen jelszóval lépsz be az oldalakra, és egy online fizetésnél milyen adatokat adsz meg.</p>
                </li>
              </ul>
            </p>
            <p class="text">Ha a fenti lista alapján úgy érzed, hogy csalás áldozata lettél, akkor a lehető leggyorsabban cselekedj. Ilyen esetben az idő valóban pénz, és a legfontosabb a károk minimalizálása. Mindenképp jelezni kell a bankoknak és egyéb szolgáltatóknak az átverés gyanúját, illetve a jelszavak cseréjét is minél hamarabb el kell végezni. Az adathalászat bűncselekménynek minősül, ezért gyakran a rendőri intézkedés sem kerülhető el.</p>
            <p class="text">Talán már te is könnyebben el tudod kerülni a csalókat. Azonban érdemes továbbra is figyelmesen, nyitott szemmel járni, hisz a bűnözők egyre kifinomultabb módszerekkel próbálkoznak, és mindig vannak újabb és újabb ötleteik a pénzszerzéshez.</p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "A nyelvtan fontosabb, mint gondolnád", 
        desc: "Neked szemet szúrnak a helyesírási hibák, amikor online tartalmat olvasol?", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "A nyelvtan fontosabb, mint gondolnád",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Neked szemet szúrnak a helyesírási hibák, amikor online tartalmat olvasol?"        
    })
  }
}
</script>